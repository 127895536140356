/*
LA GALIGO
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 31, 2022
UPDATE DATE: Oct 31, 2022
REVISION: 1
NAME: global.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: La Galigo | Liveboard
PALETTE:  1. #AE0921 / rgb(174, 9, 33) (Dark Red / Shiraz)
          2. #0274BE / rgb(2, 116, 190) (Strong Blue / Lochmara)
          3. #007D96 / rgb(0, 125, 150) (Dark Cyan / Blue Lagoon)
          4. #FFFF00 / rgb(255, 255, 0) (Pure Yellow / Yellow)
          5. #002330 / rgb(0, 35, 48) (Very Dark Blue / Daintree)
          6. #F0F3F4 / rgb(240, 243, 244) (Light Grayish Blue / Porcelain)
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@import './variable.scss';

@font-face{
  font-family: 'ClearSans';
  src: url('./../font/ClearSans.woff2') format('woff2'),
       url('./../font/ClearSans.woff') format('woff'),
       url('./../font/ClearSans.ttf') format('ttf'),
       url('./../font/ClearSans.eot') format('eot'),
       url('./../font/ClearSans.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'ClearSans';
  src: url('./../font/ClearSans-Bold.woff2') format('woff2'),
       url('./../font/ClearSans-Bold.woff') format('woff'),
       url('./../font/ClearSans-Bold.ttf') format('ttf'),
       url('./../font/ClearSans-Bold.eot') format('eot'),
       url('./../font/ClearSans-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'ClearSans';
  src: url('./../font/ClearSans-Thin.woff2') format('woff2'),
       url('./../font/ClearSans-Thin.woff') format('woff'),
       url('./../font/ClearSans-Thin.ttf') format('ttf'),
       url('./../font/ClearSans-Thin.eot') format('eot'),
       url('./../font/ClearSans-Thin.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Scriptina';
  src: url('./../font/Scriptina.woff2') format('woff2'),
       url('./../font/Scriptina.woff') format('woff'),
       url('./../font/Scriptina.ttf') format('ttf'),
       url('./../font/Scriptina.eot') format('eot'),
       url('./../font/Scriptina.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html{
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  // background: $colorBg;
  color: $colorText;
  font-size: 12px;
  line-height: 12px;
  // font-family: Helvetica, Arial, sans-serif;
  font-family: 'ClearSans', Helvetica, Arial, sans-serif;
  // font-feature-settings: 'ss02';
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a,
.link-look{
  text-decoration: none;
  color: $colorOne;
  @extend %transition;

  &:hover{
    color: $colorOne;
  }
  
  &[disabled]{
    pointer-events: none;
    opacity: .5;
  }
}

input[type="text"],
input[type="password"],
input[type="file"],
textarea,
button{
  display: block;
  color: $colorText;
  font-size: 16px;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  background: transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: 'ClearSans', Helvetica, Arial, sans-serif;
  @extend %transition;

  &:focus{
    outline: none;
  }

  &:disabled,
  &[disabled]{
    pointer-events: none;
    // opacity: .5;
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button{
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

svg{
  display: inline-block;
  vertical-align: middle;
}

section{
  position: relative;
  padding: $spacing;
  box-sizing: border-box;
  // padding: $spacing;

  &.sec-white{
    background: #FFF;
    margin-bottom: $spacingDiv2;
  }

  > h2{
    display: block;
    font-size: 16px;
    line-height: 20px;

    &.had-notif{
      position: relative;
      padding-right: 50px;
    }

    > span{
      display: block;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $colorLabel;
      margin: $spacingDiv8 0 0 0;
    }

    .title-notif{
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 25px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background: transparent;
      text-align: center;
      color: #FFF;
      @extend %transition;

      &.success{
        background: $colorSuccess;
        width: 25px;
        height: 25px;
      }

      > svg{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 25px;
        width: 25px;
        transform: translate(0, -50%);
      }
    }
  }
}

ul.floating{
  overflow: auto;
  margin: -($spacingDiv2);

  &.visible{
    overflow: visible;
  }

  > li{
    float: left;
    padding: $spacingDiv2;
    box-sizing: border-box;
    width: 100%;

    @for $i from 1 through 99{
      &.lay-#{$i} { 
        width: $i * 1%;
      }
    }
    
    &.lay-33{
      width: 33.33%;
    }
    
    &.lay-auto{
      width: auto;
    }

    &.clear{
      clear: both;
    }
  }

  &.lite{
    margin: -($spacingDiv4);

    > li{
      padding: $spacingDiv4;
    }
  }

  &.big{
    margin: -($spacing);

    > li{
      padding: $spacing;
    }
  }
}

ul.forming{
  margin: -($spacingDiv2 - 2.5);

  &.head-tab{
    overflow: visible;
    float: right;
    width: 350px;
    margin: 0 (-$spacingDiv4) (-$spacingDiv4) (-$spacingDiv4);

    > li{
      padding: $spacingDiv4;
    }
  }

  > li{
    padding: ($spacingDiv2 - 2.5);
    position: relative;
    font-size: 14px;
    line-height: 16px;

    &.req{
      color: $colorOne;
      font-size: 12px;
      line-height: 14px;
    }

    // &.anim-enter{
    //   @extend %anim-enter;
    // }

    // &.anim-enter-active{
    //   @extend %anim-enter-active;
    // }

    // &.anim-enter-done{
    //   @extend %anim-enter-done;
    // }

    // &.anim-exit{
    //   @extend %anim-exit;
    // }

    // &.anim-exit-active{
    //   @extend %anim-exit-active;
    // }

    label{
      font-size: 12px;
      line-height: 16px;
      color: rgba($colorText, .5);
      display: block;
      margin: 0 0 $spacingDiv4 0;
      
      &.text{
        position: absolute;
        top: 23px;
        left: $spacing + 5;
        font-size: 14px;
        line-height: 14px;
        pointer-events: none;
        z-index: 1;
        margin: 0;
        @extend %transition;

        &.focus{
          top: 0;
          font-size: 12px;
          line-height: 12px;
          padding: 0 $spacingDiv4;
          left: $spacing;
          background: #FFF;
        }

        > span{
          color: $colorOne;

          // &.count{
          //   display: inline-block;
          //   vertical-align: middle;
          //   font-weight: bold;
          //   font-size: 11px;
          //   line-height: 11px;
          //   background: $colorOne;
          //   color: #FFF;
          //   min-width: 15px;
          //   padding: 1px 0 3px 0;
          //   margin: -3px 0 0 0;
          //   text-align: center;
          //   border-radius: 16px;
          // }
        }
      }
      
      // &.text-qty{
      //   &.focus{
      //     left: 50%;
      //     transform: translate(-50%, 0);
      //   }
      // }

      &.toggle{
        cursor: pointer;
        position: relative;
        padding: 2px 0 3px 30px;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        color: $colorText;

        > div{
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          border: 1px solid rgba($colorText, .25);
          border-radius: $radius / 2;
          background: #FFF;
          box-sizing: border-box;
          @extend %transition;

          > svg{
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            color: #FFF;
            height: 22px;
            width: 22px;
            margin: 0 auto;
          }
        }

        > input{
          position: absolute;
          left: 0;
          top: 0;

          &:checked{
            ~ div{
              border: 1px solid $colorOne;
              background: $colorOne;
            }
          }

          &:disabled{
            display: none;

            ~ div{
              opacity: .5;
            }
          }
        }

        &:hover{
          > div{
            border: 1px solid rgba($colorText, .5);
          }
        }

        &.as-radio{
          > div{
            border-radius: 24px;

            &:after{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 2px;
              height: 2px;
              border-radius: 2px;
              background: #FFF;
              box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
              opacity: 0;
              @extend %transition;
            }
          }

          > input{
            &:checked{
              ~ div{
                &:after{
                  opacity: 1;
                  width: 12px;
                  height: 12px;
                  border-radius: 12px;
                }
              }
            }
          }
        }

        // > ul{
        //   margin: 0;

        //   > li{
        //     padding: 0;

        //     &:last-child{
        //       text-align: right;
        //       color: $colorLabel;
        //       font-size: 12px;
        //       line-height: 16px;

        //       > span{
        //         color: $colorText;
        //         font-size: 14px;
        //         line-height: 18px;
        //       }
        //     }
        //   }
        // }
      }
    }

    .check-radio{
      border: 1px solid rgba($colorText, .25);
      border-radius: $radius;
      padding: $spacingDiv2 ($spacing - 5);

      &.disabled{
        border: 1px solid rgba($colorText, .15);

        > ul{
          > li{
            > label{

              &.toggle{
                pointer-events: none;
                color: rgba(0, 0, 0, .55);
              }
            }
          }
        }
      }

      > ul{
        margin: -$spacingDiv2 (-($spacing - 5) / 2);

        > li{
          display: inline-block;
          padding: $spacingDiv2 (($spacing - 5) / 2);
        }
      }
    }

    .input-notif{
      pointer-events: none;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 18px;
      top: 50%;
      transform-origin: center center;
      transform: translate(0, -50%);
      right: ($spacing - 5) / 2 + ($spacing - 5);
      color: #FFF;
      // background: rgba($colorLabel, .5);
      background: transparent;
      @extend %transition;

      &.success{
        background: $colorSuccess;
        width: 18px;
        height: 18px;

        > svg{
          height: 9px;
        }
      }

      &.error{
        background: $colorError;
        width: 18px;
        height: 18px;
      }

      > svg{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 8px;
        width: auto;
        transform: translate(0, -50%);
      }
    }
  
    input[type="text"],
    input[type="password"],
    textarea{
      border: 1px solid rgba($colorText, .25);
      border-radius: $radius;
      height: 45px;
      padding: 0 ($spacing - 5);
      font-size: 14px;

      &.valid{
        padding-right: ($spacing - 5) + 18 + $spacingDiv4;
      }

      &:hover{
        border: 1px solid rgba($colorText, .5);
      }

      &:focus{
        border: 1px solid $colorTwo;
        box-shadow: 0 0 0 2.5px rgba($colorTwo, .25);
      }

      &:disabled{
        pointer-events: none;
        color: rgba(0, 0, 0, .75);
        border: 1px solid rgba($colorText, .15);
      }
    }

    textarea{
      resize: none;
      height: 100px;
      padding: ($spacingDiv2 + 1) ($spacing - 5);
    }

    // .label-left{
    //   pointer-events: none;
    //   position: absolute;
    //   top: 50%;
    //   transform: translate(0, -50%);
    //   left: ($spacing - 5) / 2;
    //   color: $colorLabel;
    //   height: 45px;
    //   line-height: 45px;
    //   width: 45px;
    //   text-align: center;
    //   font-weight: bold;
    //   box-sizing: border-box;
    //   z-index: 1;

    //   > span{
    //     display: inline-block;
    //     min-width: 16px;
    //     height: 16px;
    //     border-radius: 5px;
    //     background: $colorTwo;
    //     color: #FFF;
    //     font-size: 11px;
    //     line-height: 16px;
    //     margin: 0 0 0 -3px;
    //     padding: 0 0 2px 0;
    //     box-sizing: border-box;
    //   }
      
    //   > svg{
    //     width: auto;
    //     height: 20px;
    //     margin: -2px 0 0 0;
    //   }
    // }

    // &.has-label-left{
    //   label{
    //     &.text{
    //       left: (($spacing - 5) / 2) + 45;

    //       &.focus{
    //         left: $spacing;
    //       }
    //     }
    //   }
      
    //   input[type="text"],
    //   input[type="password"]{
    //     padding: 0 ($spacing - 5) 0 45px;
    //   }

    //   .react-select{
    //     .react-select__control{
    //       .react-select__value-container{
    //         padding: 0 ($spacing - 5) 0 45px;
    //       }
    //     }
    //   }
    // }

    .react-select{
      z-index: 0;

      .react-select__control{
        border: 1px solid rgba($colorText, .25);
        border-radius: $radius;
        min-height: 43px;
        box-shadow: none;
        @extend %transition;

        &:hover{
          border: 1px solid rgba($colorText, .5);
        }

        &.react-select__control--menu-is-open{
          border: 1px solid $colorTwo;
          border-bottom: 1px solid transparent;
          border-radius: $radius $radius 0 0;
          box-shadow: 0 0 0 2.5px rgba($colorTwo, .25);

          &:hover{
            border: 1px solid $colorTwo;
            border-bottom: 1px solid transparent;
          }

          .react-select__indicators{
            .react-select__dropdown-indicator{
              svg{
                transform: rotate(-180deg);
              }
            }
          }
        }

        .react-select__value-container{
          padding: 0 ($spacing - 5);

          .react-select__placeholder{
            font-size: 12px;
            line-height: 16px;
            color: $colorText;
          }

          .react-select__single-value{
            font-size: 14px;
            line-height: 18px;
            margin: 0;

            > svg{
              display: none;
            }

            > ul{
              margin: -$spacingDiv4;

              > li{
                padding: $spacingDiv4;
                line-height: 25px;
                font-size: 14px;

                &:last-child{
                  text-align: right;
                  color: $colorLabel;
                  font-size: 12px;
                }

                > img{
                  display: inline-block;
                  vertical-align: middle;
                  width: 25px;
                  height: 25px;
                  border-radius: 5px;
                  margin: -2px 0 0 0;
                }
              }
            }
          }

          .react-select__input-container{
            padding: 0;
            margin: 0;
            font-size: 14px;
            line-height: 18px;

            .react-select__input{
              height: 43px;

              &:focus{
                box-shadow: none;
              }
            }
          }
        }

        .react-select__indicators{
          .react-select__indicator-separator{
            margin: $spacingDiv15 0;
            background: rgba($colorText, .25);
          }

          .react-select__indicator{
            cursor: pointer;
          }

          .react-select__dropdown-indicator{
            svg{
              @extend %transition;
            }
          }
        }
      }
    }
  }

  &.lite{
    input[type="text"],
    input[type="password"],
    textarea{
      border-radius: $radius - 5;
      height: 40px;
      font-size: 12px;
    }

    label{
      font-size: 12px;
      line-height: 16px;
      color: rgba($colorText, .5);
      
      &.text{
        top: 17px;
      }
    }

    .react-select{
      .react-select__control{
        min-height: 40px;
        border-radius: $radius - 5;

        &.react-select__control--menu-is-open{
          border: 1px solid $colorTwo;
          border-bottom: 1px solid transparent;
          border-radius: ($radius - 5) ($radius - 5) 0 0;
        }

        .react-select__value-container{
          padding: 0 ($spacing - 5);

          .react-select__single-value{
            font-size: 12px;
            line-height: 16px;
          }

          .react-select__input-container{
            font-size: 12px;
            line-height: 16px;

            .react-select__input{
              height: auto;
            }
          }
        }
      }
    }
  }
}

.react-select__menu-portal{
  z-index: 10 !important;

  .react-select__menu{
    border-radius: 0 0 $radius $radius;
    margin: -1px 0 0 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $colorTwo;
    border-top: none;
    overflow: hidden;

    .react-select__menu-list{
      padding: 0;

      .react-select__option{
        position: relative;
        color: $colorText;
        padding: $spacingDiv2 ($spacing - 5);
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
        @extend %transition;

        &.react-select__option--is-focused{
          background: $colorBg;
        }

        &.react-select__option--is-selected{
          color: #FFF;
          background: $colorOne;

          > .check{
            opacity: 1;
          }

          > ul{
            > li{
              &:last-child{
                color: #FFF;
              }
            }
          }
        }

        &.react-select__option--is-disabled{
          opacity: .5;
        }

        > ul{
          margin: -$spacingDiv4;

          > li{
            padding: $spacingDiv4;
            line-height: 25px;
            font-size: 14px;

            &:last-child{
              text-align: right;
              color: $colorLabel;
              font-size: 12px;
            }

            > img{
              display: inline-block;
              vertical-align: middle;
              width: 25px;
              height: 25px;
              border-radius: 5px;
              margin: -2px 0 0 0;
            }
          }
        }
      }
    }
  }
}

.upload-img{
  border: 1px solid rgba($colorText, .25);
  padding: $spacing - 5;
  border-radius: $radius;

  > label{
    position: absolute;
    top: 0;
    left: $spacing;
    padding: 0 $spacingDiv4;
    font-size: 12px;
    line-height: 12px;
    color: rgba($colorText, .5);
    background: #FFF;
    z-index: 1;
    pointer-events: none;

    > span{
      color: $colorOne;
    }
  }

  &.lite{
    > label{
      top: -3px;
    }
  }

  > div{
    position: relative;
    height: 0;
    // padding: 50% 0;
    overflow: hidden;
    // background: red;
    border-radius: $radius - 10;
    @extend %transition;

    > svg{
      position: absolute;
      color: rgba($colorText, .3);
      width: 50px;
      height: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }

    > img,
    > embed,
    > iframe{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      object-fit: cover;
    }
  }

  &:hover{
    border: 1px solid rgba($colorText, .5);
  }

  &:disabled{
    pointer-events: none;
    color: rgba(0, 0, 0, .75);
    border: 1px solid rgba($colorText, .15);

    > div{
      background: rgba($colorText, .3);
      // color: #FFF;
    }
  }
}

.btn{
  display: block;
  position: relative;
  text-align: center;
  color: #FFF;
  background: $colorOne;
  border-radius: $radius;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 $spacing;
  box-shadow: 0 2.5px 5px rgba(0, 0, 0, .1);
  text-transform: uppercase;
  letter-spacing: 5px;

  &:hover{
    color: #FFF;
    background: $colorOneHover;
  }

  &.btn-outline{
    background: #FFF;
    border: 1px solid $colorOne;
    color: $colorOne;

    &:hover{
      color: #FFF;
      border: 1px solid $colorOneHover;
      background: $colorOneHover;
    }
  }

  &.btn-blue{
    background: $colorTwo;

    &:hover{
      background: $colorTwoHover;
    }
  }

  &.btn-torque{
    background: $colorThree;

    &:hover{
      background: $colorThreeHover;
    }
    
    &.btn-outline{
      background: #FFF;
      border: 1px solid $colorThree;
      color: $colorThree;
  
      &:hover{
        color: #FFF;
        border: 1px solid $colorThreeHover;
        background: $colorThreeHover;
      }
    }
  }

  &.btn-lite{
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    border-radius: $radius - 5;
  }

  &.btn-same{
    height: 45px;

    > svg{
      margin: -10px 0 0 0;
    }
  }

  &.btn-img{
    padding: 0;
    height: auto;
    background: rgba($colorText, .05);

    > img{
      display: block;
      width: 100%;
    }
  }

  &[disabled]{
    background: rgba($colorText, .3);
    color: #FFF;
  }

  > svg{
    margin: -3px 0 0 0;
    height: 18px;
    width: 18px;

    &.before{
      margin-right: 5px;
    }
  }
}

.Toastify__toast-container{
  top: 20px;
  right: 20px;
  padding: 0;

  .Toastify__toast{
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
    border-radius: $radius;
    // background: rgba($colorText, .15);
    z-index: 9;

    .Toastify__toast-body{
      font-size: 12px;
      line-height: 16px;
      font-family: 'ClearSans', Helvetica, Arial, sans-serif;

      span{
        display: block;
        margin: 2.5px 0 0 0;
        text-transform: none;
      }
    }

    .Toastify__close-button{
      width: auto;
    }

    .Toastify__progress-bar{
      background: $colorOne;
    }
  }
}

.loading{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  color: $colorText;
  text-align: center;
  margin: 0 auto;
  color: $colorLabel;

  &.section{
    clear: both;
    position: static;
    transform: none;
    margin: 100px auto;
  }

  > svg{
    display: block;
    width: 115px;
    height: 115px;
    padding: 15px;
    margin: 0 auto;
    
    > path{
      transform-origin: center center;
    }

    .ico-1{
      animation: rotate-left 1.0s infinite ease-in-out;
    }

    .ico-4{
      animation: rotate-left-top 1.0s infinite ease-in-out;
    }

    .ico-2{
      animation: rotate-right 1.0s infinite ease-in-out;
    }

    .ico-3{
      animation: rotate-right-top 1.0s infinite ease-in-out;
    }

    .ico-5{
      // x: 16.49px;
      // y: 31.56px;
      animation: square 1.0s infinite ease-in-out;
    }
  }
}

@keyframes rotate-left{
  0% { opacity: 0; transform: rotate(180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes rotate-left-top{
  0% { opacity: 0; transform: rotate(-180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes rotate-right{
  0% { opacity: 0; transform: rotate(-180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes rotate-right-top{
  0% { opacity: 0; transform: rotate(180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes square{
  0% { opacity: 0; x: 22.49px; y: 26.56px; width: 1.89px; height: 1.89px; }
  80% { opacity: 1; x: 16.49px; y: 31.56px; width: 2.89px; height: 2.89px; }
  100% { opacity: 0; x: 14.49px; y: 33.56px; width: 2.89px; height: 2.89px; }
}

ul.loading-in{
  margin: -4px 0 0 0;

  > li{
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    background: $colorOne;
    margin: 0 $spacingDiv4;
    transform: rotate(45deg);
    animation: rotate-in .9s infinite ease-in-out;

    &:nth-child(1){
      animation-delay: -.2s;
    }

    &:nth-child(2){
      animation-delay: -.1s;
    }
  }

  &.loading-up{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

@keyframes rotate-in{
  0% { opacity: 0; transform: rotate(0deg) scale(.5); border-radius: 7px; }
  40% { opacity: 1; transform: rotate(45deg) scale(1); border-radius: 0; }
  60% { opacity: 1; transform: rotate(45deg) scale(1); border-radius: 0; }
  100% { opacity: 0; transform: rotate(90deg) scale(.5); border-radius: 7px; }
}

// .container{
//   position: relative;
//   max-width: $maxWidth;
//   margin: 0 auto;
//   background: $colorBg;
//   min-height: 100vh;
// }

.form-login{
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 500px;
  margin: 0 auto;
  padding: $spacing;
  box-sizing: border-box;

  > ul{
    > li{
      > svg{
        display: block;
        max-width: 400px;
        margin: 0 auto;
      }

      > button{
        max-width: 350px;
        margin: 0 auto;
      }

      &.title{
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 10px;

        > span{
          display: block;
          font-family: 'Scriptina', 'ClearSans', Helvetica, Arial, sans-serif;
          font-size: 48px;
          line-height: 54px;
          text-transform: none;
          color: $colorOne;
          letter-spacing: 0;
          margin: $spacingDiv2 0 $spacingDiv4 0;
        }
      }
    }
  }
}

.copy{
  text-align: center;
  color: $colorLabel;
  font-size: 11px;
  line-height: 15px;

  > a{
    font-weight: bold;
  }
}

.gall{
  margin: -1px !important;
  margin-top: 1px !important;

  > li{
    padding: 1px !important;

    .btn-img{
      border-radius: $radius / 2;
    }
  }
}