/*
LA GALIGO
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 31, 2022
UPDATE DATE: Oct 31, 2022
REVISION: 1
NAME: variable.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: La Galigo | Liveboard
PALETTE:  1. #AE0921 / rgb(174, 9, 33) (Dark Red / Shiraz)
          2. #0274BE / rgb(2, 116, 190) (Strong Blue / Lochmara)
          3. #007D96 / rgb(0, 125, 150) (Dark Cyan / Blue Lagoon)
          4. #FFFF00 / rgb(255, 255, 0) (Pure Yellow / Yellow)
          5. #002330 / rgb(0, 35, 48) (Very Dark Blue / Daintree)
          6. #F0F3F4 / rgb(240, 243, 244) (Light Grayish Blue / Porcelain)
*/

$colorOne: #AE0921;
$colorOneHover: #8E0901;
$colorTwo: #0274BE;
$colorTwoHover: #02549E;
$colorThree: #007D96;
$colorThreeHover: #005D76;
$colorFour: #FFFF00;
$colorText: #002330;
$colorLabel: rgba($colorText, .7);
$colorBg: #F0F3F4;

$colorSuccess: green;
// $colorWarning: red;
$colorError: red;

$spacing: 20px;
$spacingMul125: calc($spacing * 1.25);
$spacingMul15: calc($spacing * 1.5);
$spacingMul2: calc($spacing * 2);
$spacingDiv125: calc($spacing / 1.25);
$spacingDiv15: calc($spacing / 1.5);
$spacingDiv2: calc($spacing / 2);
$spacingDiv4: calc($spacing / 4);
$spacingDiv6: calc($spacing / 6);
$spacingDiv8: calc($spacing / 8);
$radius: 15px;
// $radiusOutMul125: calc($radiusOut * 1.25);
// $radiusIn: calc($radiusOut / 2);
// $radiusInDiv125: calc($radiusIn / 1.25);
// $radiusInDiv2: calc($radiusIn / 2);
$maxWidth: 390px;

$border: 1px solid rgba($colorText, .1);

// $primaryBorderColor: #CDE8E7;
// $primaryBorderColorHover: rgba(112, 112, 112, .5);
// $secondaryBorderColor: rgba(112, 112, 112, .15);
// $primaryBorder: 1px solid $primaryBorderColor;
// $secondaryBorder: 1px solid $secondaryBorderColor;

%transition{
  transition: all 200ms ease-in-out !important;
  -moz-transition: all 200ms ease-in-out !important;
  -webkit-transition: all 200ms ease-in-out !important;
}

%transitionAnim{
  transition: all 300ms ease-in-out !important;
  -moz-transition: all 300ms ease-in-out !important;
  -webkit-transition: all 300ms ease-in-out !important;
}

%gradient{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#007d96+0,0274be+100 */
  background: $colorThree; /* Old browsers */
  background: -moz-linear-gradient(left,  $colorThree 0%, $colorTwo 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  $colorThree 0%,$colorTwo 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  $colorThree 0%,$colorTwo 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007d96', endColorstr='#0274be',GradientType=1 ); /* IE6-9 */
}

%anim-enter {
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transform: scale(.9);
  // padding-left: 20px;
  // padding-right: 20px;
}

%anim-enter-active{
  opacity: 1;
  overflow: hidden;
  max-height: 50vh;
  transform: scale(1);
  // padding-left: 0;
  // padding-right: 0;
  @extend %transitionAnim;
}

%anim-enter-done{
  max-height: none;
}

%anim-exit {
  opacity: 1;
  max-height: 50vh;
  transform: scale(1);
  // padding-left: 0;
  // padding-right: 0;
}

%anim-exit-active{
  opacity: 0;
  max-height: 0;
  transform: scale(.9);
  // padding-left: 20px;
  // padding-right: 20px;
  @extend %transitionAnim;
}

// %transitionslow{
//     transition: all 500ms ease-in-out !important;
//     -moz-transition: all 500ms ease-in-out !important;
//     -webkit-transition: all 500ms ease-in-out !important;
// }

// %static{
//     position: static;
//     transform: none;
// }